import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const ParryIcon: FC<ICommonIconProps> = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  color,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.9286 17.7424L22.4202 16.2509L14.4653 8.29595L10.4878 12.2734L6.51028 8.29595L9.87586 4.93037L7.00748 2.8269L0 9.78751L5.51592 17.2452L10.4878 22.2171L11.9793 20.7255L8.9962 17.7424L9.99052 16.748L13.968 20.7255L15.4596 19.234L11.4821 15.2565L12.4764 14.2621L17.4484 19.234L18.9399 17.7424L13.968 12.7705L14.9625 11.7762L20.9286 17.7424Z"
      fill={color ?? 'currentcolor'}
    />
    <path
      d="M12.4764 4.31845L8.49902 8.29593L10.4877 10.2846L14.4651 6.30698L15.4622 7.30406C16.2849 8.12418 17.6205 8.12366 18.4422 7.3019L19.4365 8.29616C18.8748 8.85777 18.1678 9.17634 17.4357 9.27773L21.4258 13.2677L24 8.29593L19.031 1.78284L12.4764 4.31845Z"
      fill={color ?? 'currentcolor'}
    />
  </svg>
);
