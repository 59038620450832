import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { getUserGeo } from 'api';
import { Button, Typography } from 'ui-kit';

import { useStyle } from './DiscordBanner.styles';

export const DiscordBanner: FC = () => {
  const { classes } = useStyle();
  const { Headline } = Typography;
  const { t: translation } = useTranslation();

  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isContactPay = useMemo(() => geo?.country === 'RU', [geo?.country]);
  const social = useMemo(
    () => (isContactPay ? 'telegramBanner' : 'discordBanner'),
    [isContactPay]
  );
  const socialLink = useMemo(
    () =>
      isContactPay
        ? 'https://web.telegram.org/a/#-1002417224132'
        : 'https://discord.gg/7fKEWhnh4x',
    [isContactPay]
  );
  return (
    <div className={classes.bannerContainer}>
      <Headline variant="h2" block>
        {translation(`profile.${social}.title`)}
      </Headline>
      <Button className={classes.btn} buttonSize="xl">
        <a href={socialLink} target="_blank" rel="noreferrer">
          {translation(`profile.${social}.btn`)}
        </a>
      </Button>
    </div>
  );
};
