import { FC } from 'react';

import clsx from 'clsx';

import { useStyle } from './Headline.styles';

export type HeadlineVariantType = 'h0' | 'h1' | 'h2' | 'h3' | 'h4';

export type HeadlineColorType =
  | 'primary'
  | 'secondary'
  | 'brand'
  | 'system'
  | 'button'
  | 'warning'
  | 'yellow'
  | 'red'
  | 'link'
  | 'typoPlaceholder'
  | 'greenLight';

export interface IHeadlineProps {
  variant?: HeadlineVariantType;
  color?: HeadlineColorType;
  children: React.ReactNode | string;
  className?: string;
  block?: boolean;
}

export const Headline: FC<IHeadlineProps> = ({
  variant = 'h1',
  color = 'primary',
  children,
  className,
  block = false,
}) => {
  const { classes } = useStyle({ variant, color, block });
  return <span className={clsx(classes.headline, className)}>{children}</span>;
};
