import React, { FC, ReactNode, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside, useLoginServices } from '../../../../hooks';
import {
  CloseIcon,
  ParryIcon,
  PredictionIcon,
  SteamIcon,
  SwordsIcon,
} from '../../../../icons';
import { Button, Typography } from '../../../../ui-kit';
import { Modal } from '../Modal';
import { useStyle } from './AboutProductModal.styles';

interface IAboutProductModal {
  isOpen: boolean;
  onClose: () => void;
}

const GREEN_LIGHT_COLOR = '#03D7BD';

export const AboutProductModal: FC<IAboutProductModal> = ({
  isOpen = false,
  onClose,
}) => {
  const ref = useRef(null);
  const { classes } = useStyle();
  const { t: translation } = useTranslation();

  useClickOutside(ref, onClose);

  const { Headline, Text } = Typography;

  const { onSteamClick, steamUrlError } = useLoginServices('');

  const content: Array<{
    icon: ReactNode;
    title: string;
    description: string;
  }> = [
    {
      title: translation('modal.aboutProduct.content.prediction.title'),
      description: translation(
        'modal.aboutProduct.content.prediction.description'
      ),
      icon: <PredictionIcon color={GREEN_LIGHT_COLOR} width={40} height={40} />,
    },
    {
      title: translation('modal.aboutProduct.content.challenge.title'),
      description: translation(
        'modal.aboutProduct.content.challenge.description'
      ),
      icon: <SwordsIcon color={GREEN_LIGHT_COLOR} width={40} height={40} />,
    },
    {
      title: translation('modal.aboutProduct.content.parry.title'),
      description: translation('modal.aboutProduct.content.parry.description'),
      icon: <ParryIcon color={GREEN_LIGHT_COLOR} width={40} height={40} />,
    },
  ];

  return (
    <Modal isOpen={isOpen}>
      <div ref={ref} className={classes.modal}>
        <div className={classes.header}>
          <button onClick={onClose} className={classes.close}>
            <CloseIcon color="#fff" />
          </button>
          <Headline className={classes.title}>
            {translation('modal.aboutProduct.title')}
          </Headline>
        </div>
        <div className={classes.contentContainer}>
          <Text>{translation('modal.aboutProduct.subTitle')}</Text>
          <div className={classes.faq}>
            <Headline block variant="h3">
              {translation('modal.aboutProduct.content.question.howWorks')}
            </Headline>
            <Text>
              {translation('modal.aboutProduct.content.question.answer')}
            </Text>
          </div>
          <div className={classes.content}>
            <ul>
              {content.map(({ title, description, icon }) => (
                <li key={title}>
                  <div className={classes.contentItem}>
                    <div className={classes.icon}>{icon}</div>
                    <Headline block variant="h3" color="greenLight">
                      {title}
                    </Headline>
                    <Text>{description}</Text>
                  </div>
                </li>
              ))}
            </ul>
            <Headline block variant="h3">
              {translation('modal.aboutProduct.content.warning.title')}
            </Headline>
            <Text>
              {translation('modal.aboutProduct.content.warning.description')}
            </Text>
          </div>
          <Button
            color="primary"
            buttonSize="xl"
            onClick={onSteamClick}
            disabled={!!steamUrlError}
          >
            <SteamIcon className="mr-2.5" />
            {translation('modal.aboutProduct.steamConnect')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
