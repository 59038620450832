import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  root: {
    width: 'calc(100% - 72px)',
    height: '120px',
    backgroundColor: 'transparent',

    marginLeft: 72,
    marginTop: 24,
    display: 'flex',
    gap: 16,
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '8px 16px',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  link: { cursor: 'pointer' },
  line: {
    display: 'flex',
    gap: 8,
  },
  mail: {
    textDecoration: 'underline',
  },
  d3text: {
    maxWidth: 153,
    widht: '100%',
  },
  img: {
    height: '52px',
  },
  twofourWhiteImg: {
    height: '36px',
  },
}));
