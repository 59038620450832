import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { getUserGeo } from 'api';
import { Button, Typography } from 'ui-kit';

import { useStyle } from './TwitterCard.styles';

export function TwitterCard(): JSX.Element {
  const { t: translation } = useTranslation();

  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  const { data: geo } = useSWR('api/geo', getUserGeo);
  console.log(geo);
  return (
    <div className={classes.discordCard}>
      <Headline className={classes.discordAd}>
        {geo?.country !== 'RU'
          ? translation('home.twitter.title')
          : translation('home.telegram.title')}
      </Headline>
      <Text variant="b2" className={classes.discordText}>
        {geo?.country !== 'RU'
          ? translation('home.twitter.text')
          : translation('home.telegram.text')}
      </Text>
      <a
        href={
          geo?.country !== 'RU'
            ? 'https://twitter.com/greed_ltd'
            : 'https://t.me/GameGreed'
        }
        className={classes.btnLink}
        target="_blank"
        rel="noreferrer"
      >
        <Button color="brand" buttonSize="xl">
          {geo?.country !== 'RU'
            ? translation('home.twitter.btnText')
            : translation('home.telegram.btnText')}
        </Button>
      </a>
    </div>
  );
}
