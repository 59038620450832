import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const StreamOnlineIcon: FC<ICommonIconProps> = ({
  color = '#44474B',
  width = 16,
  height = 16,
  viewBox = '0 0 17 17',
  style,
  className = '',
}) => (
  <svg
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3418 8.94151L6.3418 12.4056L6.3418 5.47741L12.3418 8.94151Z"
      fill={color}
    />
    <circle
      cx="8.34155"
      cy="8.94153"
      r="7.59408"
      stroke={color}
      strokeWidth="0.811832"
    />
  </svg>
);
