import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SystemIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import { ModalContext } from '../../../../../components';
import { useStyle } from './Header.styles';

export default function Header(): JSX.Element {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Text, Headline } = Typography;
  const {
    aboutProduct: { openAboutProduct },
  } = useContext(ModalContext);
  return (
    <div className={classes.hero}>
      <div className={classes.heroContent}>
        <Headline className={classes.heroTitle}>
          {translation('home.header.title')}
        </Headline>
        <Text className={classes.heroText} variant="b3">
          {translation('home.header.description')}
        </Text>
        <div className={classes.heroLinkContainer}>
          <Button
            className={classes.heroBtn}
            color="brand"
            buttonSize="xl"
            onClick={openAboutProduct}
          >
            {translation('home.header.btnText')}
          </Button>
          {/* <a
            href="https://info.greed.ltd/"
            className={classes.cryptoLink}
            target="_blank"
            rel="noreferrer"
          >
            <Text variant="b3">{translation('home.header.cryptoLink')}</Text>
            <SystemIcon className={classes.cryptoIcon} />
          </a> */}
        </div>
      </div>
      <img
        src="images/main-page/nightstalker.png"
        alt="traxex"
        className={classes.heroImage}
      />
    </div>
  );
}
