import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    gap: 24,
    width: '100%',
    overflow: 'hidden',
    marginBottom: 40,
    '@media (max-width: 1400px)': {
      paddingRight: 8,
    },
  },
  slardarImg: {
    width: '50%',
    position: 'absolute',
    top: '30px',
    left: '25px',
    transform: 'scale(1.1)',
  },
  content: {
    paddingTop: 50,
    width: '45%',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  contentList: {
    width: 350,
  },
  contentItem: {
    marginBottom: 50,
  },
  videoContainer: {
    backgroundClip: 'padding-box',
    position: 'relative',
    backgroundImage: "url('images/main-page/video-block/video-bg-2.jpg')",
    backgroundSize: 'cover',
    width: 932,
    height: 500,
    '&:before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      borderRadius: '6px',
      padding: '2px',
      background: 'linear-gradient( 88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
      mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      maskComposite: 'xor',
      webkitMaskComposite: 'xor',
    },
  },
  banner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 276,
    height: 500,
    borderRadius: 6,
    backgroundImage: "url('images/main-page/video-block/cancel.jpg')",
    backgroundSize: 'cover',
    padding: '24px 32px',
  },
  innerBanner: {
    backgroundImage: "url('images/main-page/video-block/cancel.jpg')",
  },
  description: {
    width: '100%',
    display: 'inline-block',
    marginTop: 16,
  },
  arcana: {
    position: 'absolute',
    right: 0,
    top: 280,
    paddingLeft: 30,
    background:
      'linear-gradient(88.89deg, rgba(3, 215, 189, 0.2) 0.73%, rgba(0, 209, 255, 0.2) 102.93%)',
    borderRadius: '6px 0px 0px 6px',
    width: 182,
    height: 68,
    ...theme.layouts.flexCenter,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    width: 'fit-content',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.typo.system,
    marginTop: 'auto',
    marginBottom: 0,
  },
}));
