import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { TextLogoIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import { useStyle } from './VideoBlock.style';

const nonLoginBanners = [
  'url(images/main-page/video-block/banner.jpg)',
  'url(images/main-page/video-block/IMG_20241108_215815_195.png)',
];
const nonLoginTitles = ['home.videoBlock.title', ''];
const nonLoginDescs = ['home.videoBlock.description', ''];
const nonButtons = ['home.videoBlock.btn', 'home.videoBlock.btnMore'];

const banners = [
  'url(images/main-page/video-block/IMG_20241108_215815_195.png)',
  'url(images/main-page/video-block/banner-invoker.png)',
  'url(images/main-page/video-block/banner-leoric.png)',
];
const titles = [
  '',
  'home.videoBlock.innerTitle',
  'home.videoBlock.innerTitleSecondary',
];

const descs = ['', 'home.videoBlock.innerDescription', ''];

const buttons = [
  {
    text: 'home.videoBlock.btnMore',
    link: 'https://battlepass.ru/greed',
  },
  {
    text: 'home.videoBlock.innerBtn',
    link: '/challenges',
  },
  {
    text: 'home.videoBlock.innerBtn',
    link: '/challenges',
  },
];

export const VideoBlock: FC = () => {
  const { classes, cx } = useStyle();
  const { t: translation } = useTranslation();
  const { data: user } = useSWR('api/user');
  const isLogin = user?.id;

  const navigate = useNavigate();

  const {
    loginModal: { openLogin },
  } = useContext(ModalContext);
  const { Headline, Text } = Typography;

  const [imageIndex, setImageIndex] = useState(0);
  const currentBanner = useMemo(
    () => (isLogin ? banners : nonLoginBanners),
    [isLogin]
  );
  const currentTitles = useMemo(
    () => (isLogin ? titles : nonLoginTitles),
    [isLogin]
  );
  const currentDescs = useMemo(
    () => (isLogin ? descs : nonLoginDescs),
    [isLogin]
  );
  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setImageIndex((prev) => {
          return prev < currentBanner.length - 1 ? prev + 1 : 0;
        }),
      5000
    );
    return () => clearInterval(intervalId);
  }, [currentBanner]);

  return (
    <div className={classes.root}>
      <div
        className={classes.videoContainer}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {/* <ReactPlayer
          width={'100%'}
          height={'100%'}
          url="https://www.youtube.com/watch?v=rGOQncWs1oc"
        /> */}
        <img
          className={classes.slardarImg}
          src="images/main-page/video-block/slardar.png"
          alt=""
        />
        <div className={classes.content}>
          <Headline block className={classes.contentItem}>
            {translation('home.videoBlock.howUsePlatform')}
          </Headline>
          <ul className={classes.contentList}>
            <li className={classes.contentItem}>
              <Text>{translation('home.videoBlock.step1.before')}</Text>
              <Text color="greenLight">
                {translation('home.videoBlock.step1.greenText')}
              </Text>
              <Text>{translation('home.videoBlock.step1.after')}</Text>
            </li>
            <li className={classes.contentItem}>
              <Text>{translation('home.videoBlock.step2.before')}</Text>
              <Text color="greenLight">
                {translation('home.videoBlock.step2.greenText')}
              </Text>
              <Text>{translation('home.videoBlock.step2.after')}</Text>
            </li>
            <li className={classes.contentItem}>
              <Text>{translation('home.videoBlock.step3.before')}</Text>
              <Text color="greenLight">
                {translation('home.videoBlock.step3.greenText')}
              </Text>
              <Text>{translation('home.videoBlock.step3.after')}</Text>
            </li>
          </ul>
        </div>
      </div>
      <div
        key={imageIndex}
        className={cx(
          classes.banner,
          isLogin && cx(classes.banner, classes.innerBanner)
        )}
        style={{
          backgroundImage: currentBanner[imageIndex],
        }}
      >
        <div>
          <Headline variant="h2">
            {translation(currentTitles[imageIndex])}
          </Headline>
          <Text className={classes.description} variant="b3">
            {translation(currentDescs[imageIndex])}
          </Text>
        </div>
        {isLogin ? (
          // <a
          //   href="https://gamegreed.gitbook.io/gamegreed"
          //   target="_blank"
          //   rel="noreferrer"
          // >
          <a href={buttons[imageIndex].link}>
            <Button
              color="brand"
              buttonSize="l"
              style={{
                width: '100%',
              }}
            >
              {translation(buttons[imageIndex].text)}
            </Button>
          </a>
        ) : (
          // </a>
          <Button color="brand" buttonSize="l" onClick={openLogin}>
            {translation(nonButtons[imageIndex])}
          </Button>
        )}
      </div>
    </div>
  );
};
