import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { HeadlineColorType, HeadlineVariantType } from './Headline';

export const useStyle = makeStyles<{
  color: HeadlineColorType;
  variant: HeadlineVariantType;
  block: boolean;
}>()((theme, { variant, color, block }) => {
  const fontStyleMap: Record<HeadlineVariantType, CSSObject> = {
    h0: {
      fontSize: 56,
      fontWeight: 700,
    },
    h1: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '40px',
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '30px',
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '26px',
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
    },
  };

  const colorMap: Record<HeadlineColorType, CSSObject> = {
    primary: {
      color: theme.palette.typo.primary,
    },
    secondary: {
      color: theme.palette.typo.primary,
      opacity: 0.4,
    },
    warning: {
      color: theme.palette.typo.warning,
    },
    brand: {
      backgroundImage:
        'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
      backgroundClip: 'text',
      color: 'transparent',
    },
    system: {
      color: theme.palette.typo.system,
    },
    button: {
      color: theme.palette.typo.link,
      transitionDuration: '300ms',
      ':hover': {
        color: theme.palette.typo.primary,
        transitionDuration: '300ms',
      },
      ':active': {
        color: theme.palette.typo.primary,
      },
    },
    yellow: {
      color: theme.palette.typo.warning,
    },
    red: {
      color: theme.palette.typo.alert,
    },
    greenLight: {
      color: theme.palette.typo.greenLight,
    },
    link: {
      color: theme.palette.typo.link,
      transitionDuration: '300ms',
      ':hover': {
        color: theme.palette.typo.linkHover,
        transitionDuration: '300ms',
      },
      ':active': {
        color: theme.palette.typo.linkPressed,
        transitionDuration: '300ms',
      },
    },
    typoPlaceholder: {
      color: theme.palette.control.default.typoPlaceholder,
    },
  };
  return {
    headline: {
      ...theme.fontStyles.fontFamilyUbuntu,
      ...fontStyleMap[variant],
      ...colorMap[color],
      display: block ? 'block' : 'inline',
    },
  };
});
