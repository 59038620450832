import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  root: {
    backgroundSize: 'cover',
    width: '100%',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '32px',
    color: 'white',
  },
  img: {
    width: '63%',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
  },
  footer: {
    marginLeft: 0,
    width: '100%',
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
  },
  description: {
    maxWidth: '319px',
    textAlign: 'center',
  },
}));
