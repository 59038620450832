import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './FailedPayment.styles';

export const FailedPayment: FC = () => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { Headline, Text } = Typography;
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img
          alt="failed"
          src="./images/profile/failed.png"
          className={classes.img}
        />
        <div className={classes.titles}>
          <Headline variant="h0" className={classes.title}>
            {translation('payFail.title')}
          </Headline>
          <Headline variant="h1" color="brand">
            {translation('payFail.subTitle')}
          </Headline>
        </div>
        <Text variant="b6" className={classes.description}>
          {translation('payFail.description')}
        </Text>
        <Button onClick={() => navigate('/home')}>
          {translation('notFound.homeButton')}
        </Button>
      </div>
    </div>
  );
};
