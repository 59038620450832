import { IRoutes } from 'types/interfaces';

import { CHALLENGES, CHALLENGES_LIST } from './Challenges';
import { FAILED_PAY } from './FailedPayment';
import { HOME } from './Home';
import { PARRIES, PARRY_LIST } from './Parries';
import { PROFILE } from './Profile';

export { LoginReferral } from './LoginReferral';
export { NotFound } from './NotFound';

export const ROUTES: IRoutes[] = [HOME, ...CHALLENGES, ...PARRIES, FAILED_PAY];

export const AUTH_ROUTES: IRoutes[] = [PROFILE];

export const DISABLED_SUBROUTES = [
  CHALLENGES_LIST.routes[2],
  PARRY_LIST.routes[2],
];
