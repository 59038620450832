import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    overflow: 'auto',
    width: 900,
    height: '95vh',
  },
  header: {
    position: 'relative',
    backgroundImage: 'url("/images/modals/about-product-modal-bg.png")',
    height: 400,
  },
  close: {
    position: 'absolute',
    top: 30,
    right: 30,
  },
  title: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  contentContainer: {
    textAlign: 'center',
    padding: '30px 100px',
    backgroundColor: '#202333',
  },
  faq: {
    marginTop: 50,
  },
  content: {
    margin: '40px 0',
    textAlign: 'left',
  },
  contentItem: {
    marginBottom: 40,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    left: '-60px',
  },
}));
