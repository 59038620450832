import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 1rem 1rem 2rem',
    backgroundColor: theme.palette.bg.toneLight,
    margin: '2rem 0.5rem 2rem 0',
    borderRadius: '4px',
    gap: '1rem',
    height: 300,
  },
  streamers: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarWrapper: {
    overflow: 'hidden',
    borderRadius: '50%',
    marginBottom: 10,
  },
  avatar: {
    width: 200,
    transform: 'scale(1.01)',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '0.3rem',
  },
  arrow: {
    backgroundColor: theme.palette.bg.ghostLight,
    width: 32,
    height: 32,
    borderRadius: '50%',
    padding: '0.5rem',
    cursor: 'pointer',
  },
}));
