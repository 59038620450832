import { FailedPayment } from './FailedPayment';

export const FAILED_PAY = {
  name: 'failed_payment',
  routes: [
    {
      title: 'failed_payment',
      path: '/failed_payment',
      element: <FailedPayment />,
    },
  ],
};
