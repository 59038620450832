import { useState } from 'react';

import { ArrowIcon, StreamOnlineIcon } from 'icons';
import { Typography } from 'ui-kit';

import { useStyle } from './Streamers.styles';

type TStreamer = {
  name: string;
  avatar: string;
  url: string;
  isOnline: boolean;
};

const ONLINE_COLOR = '#03D7BD';

const streamers: Array<TStreamer> = [
  {
    name: 'Meepoplayer',
    avatar: '/images/home/streamers/Meepoplayer.jpg',
    url: 'https://www.twitch.tv/meepoplayer',
    isOnline: true,
  },
  {
    name: 'Canceldota',
    avatar: '/images/home/streamers/Canceldota.jpg',
    url: 'https://www.twitch.tv/canceldota',
    isOnline: false,
  },
  {
    name: 'Ar1se',
    avatar: '/images/home/streamers/Ar1se.jpg',
    url: 'https://www.twitch.tv/ar1sedota',
    isOnline: true,
  },
  {
    name: 'Finargot',
    avatar: '/images/home/streamers/Finargot.jpg',
    url: 'https://www.twitch.tv/finargot',
    isOnline: false,
  },
];

const { Headline, Text } = Typography;

function Card({ streamer }: { streamer: TStreamer }): JSX.Element {
  const { avatar, name, url, isOnline } = streamer ?? {
    avatar: '',
    name: '',
    url: '',
    isOnline: false,
  };
  const { classes } = useStyle();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className={classes.avatarWrapper}>
        <img src={avatar} className={classes.avatar} />
      </div>
      <div className={classes.textWrapper}>
        <Text variant="b6">{name}</Text>
        <StreamOnlineIcon color={isOnline ? ONLINE_COLOR : undefined} />
      </div>
    </a>
  );
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Array<T> {
    wrap(start: number, end: number): Array<T>;
  }
}

Array.prototype.wrap = function (start, end) {
  const res = [];
  let index = start;

  for (let i = start; i < end; i++) {
    if (index >= this.length) {
      index = 0;
    }

    res.push(this[index]);
    index++;
  }
  return res;
};

export function Streamers(): JSX.Element {
  const { classes, theme } = useStyle();
  const [index, setIndex] = useState(0);

  const relativeIndex: number = index % streamers.length;
  const SHOWN_ITEMS = 4;

  return (
    <div className={classes.root}>
      <Headline variant="h3">Our streamers</Headline>
      <div className={classes.streamers}>
        <div onClick={() => index > 0 && setIndex(index - 1)}>
          <ArrowIcon
            style={{ transform: 'rotate(90deg)' }}
            color={theme.palette.typo.primary}
            className={classes.arrow}
          />
        </div>
        {streamers
          .wrap(relativeIndex, relativeIndex + SHOWN_ITEMS)
          .map((streamer) => (
            <Card key={streamer.name} streamer={streamer} />
          ))}
        <div onClick={() => setIndex(index + 1)}>
          <ArrowIcon
            style={{ transform: 'rotate(-90deg)' }}
            color={theme.palette.typo.primary}
            className={classes.arrow}
          />
        </div>
      </div>
    </div>
  );
}
