import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modalContainer: {
    padding: '16px 20px',
    borderRadius: 8,
    backgroundColor: theme.palette.bg.system,
    maxWidth: 700,
  },
  form: {
    backgroundColor: '#2B3353',
    padding: '16px 20px',
    borderRadius: 6,
  },
  title: {
    textAlign: 'center',
    marginTop: '40px',
  },
  textWarning: {
    textAlign: 'center',
    margin: '20px 0',
  },
  description: {
    margin: '12px 0',
  },
  promoBanner: {
    width: '100%',
    backgroundImage:
      'linear-gradient(89.03deg, #702BC8 0.64%, #3452BE 175.76%)',
    borderRadius: 8,
    padding: '15px 45px 15px 22px',
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    boxSizing: 'border-box',
    maxHeight: 50,
    margin: '32px 0',
  },
  inputContainer: {
    display: 'flex',
    gap: 22,
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  bottomContainer: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taxContainer: {
    display: 'flex',
    gap: 16,
  },
  taxDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  buttonContainer: {
    display: 'flex',
    gap: 16,
    marginRight: 0,
    marginLeft: 'auto',
  },
  buyBtn: {
    minWidth: 160,
  },
  paymentDescription: {
    width: 'max-content',
  },
  paymentTexts: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '6px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  paymentImageContainer: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #8188A2',
    borderRadius: '4px',
  },
  paymentButtom: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #8188A2',
    borderRadius: '6px',
  },
  paymentButtoms: {
    display: 'flex',
    gap: '20px',
  },
  activeBorder: {
    borderColor: '#2D63FF',
  },
}));
