import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { TextColorType, TextVariantType } from './Text';

export const useStyle = makeStyles<{
  color: TextColorType;
  variant: TextVariantType;
  block: boolean;
}>()((theme, { variant, color, block }) => {
  const fontStyleMap: Record<TextVariantType, CSSObject> = {
    b1: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '24px',
    },
    b2: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '22px',
    },
    b3: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    b4: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
    },
    b5: { fontSize: 14, lineHeight: '20px', fontWeight: 400 },
    b6: { fontSize: 12, lineHeight: '16px', fontWeight: 600 },
    b7: { fontSize: 10, lineHeight: '12px', fontWeight: 400 },
    b8: { fontSize: 8, lineHeight: '8px', fontWeight: 200 },
  };

  const colorMap: Record<TextColorType, CSSObject> = {
    primary: {
      color: theme.palette.typo.primary,
    },
    secondary: {
      color: theme.palette.typo.primary,
      opacity: 0.4,
    },
    brand: {
      backgroundImage:
        'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
      backgroundClip: 'text',
      color: 'transparent',
    },
    system: {
      color: theme.palette.typo.system,
    },
    link: {
      color: theme.palette.typo.link,
      transitionDuration: '300ms',
      ':hover': {
        color: theme.palette.typo.linkHover,
        transitionDuration: '300ms',
      },
      ':active': {
        color: theme.palette.typo.linkPressed,
        transitionDuration: '300ms',
      },
    },
    alert: {
      color: theme.palette.typo.alert,
    },
    success: {
      color: theme.palette.typo.success,
    },
    tertiary: {
      color: theme.palette.typo.tertiary,
    },
    warning: {
      color: theme.palette.typo.warning,
    },
    menu2: {
      color: theme.palette.typo.system,
    },
    typoLabel: {
      color: theme.palette.control.default.typoLabel,
    },
    yellow: {
      color: theme.palette.typo.warning,
    },
    red: {
      color: theme.palette.typo.alert,
    },
    label: {
      color: theme.palette.typo.alert,
    },
    typoPlaceholder: {
      color: theme.palette.control.default.typoPlaceholder,
    },
    bronze: {
      color: theme.palette.bronze,
    },
    silver: {
      color: theme.palette.silver,
    },
    gold: {
      color: theme.palette.gold,
    },
    lightGreen: {
      color: theme.palette.typo.primary,
    },
    greenLight: {
      color: theme.palette.typo.greenLight,
    },
  };
  return {
    text: {
      ...theme.fontStyles.fontFamilyNunito,
      ...fontStyleMap[variant],
      ...colorMap[color],
      display: block ? 'block' : 'inline',
    },
  };
});
