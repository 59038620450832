import React, { forwardRef, useState } from 'react';

import clsx from 'clsx';
import NumberFormat, {
  InputAttributes,
  NumberFormatProps,
} from 'react-number-format';

import { ErrorMessage, Tooltip, Typography } from 'ui-kit';

import { useStyle } from './InputNumber.styles';

export type InputNumberSizeType = 'sm' | 'md' | 'l';

export interface IInputNumberProps extends NumberFormatProps {
  error?: string;
  tooltip?: string | React.ReactNode;
  title?: string;
  inputSize?: InputNumberSizeType;
  withError?: boolean;
}

export const InputNumber = forwardRef<
  NumberFormat<InputAttributes>,
  IInputNumberProps
>(
  (
    {
      title = '',
      error = '',
      tooltip = '',
      disabled = false,
      className = '',
      inputSize = 'md',
      withError = true,
      ...rest
    },
    ref
  ) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const { classes } = useStyle({ size: inputSize, withError });
    const { Text } = Typography;
    return (
      <div className={clsx(classes.inputContainer, className)}>
        {title && (
          <Text variant="b6" color="typoLabel" className={classes.title}>
            {title}
          </Text>
        )}
        <Tooltip
          position={'right'}
          content={tooltip}
          isVisible={isTooltipVisible}
          color="blue"
          type="focus"
        >
          <Text variant="b5">
            <NumberFormat
              getInputRef={ref}
              className={clsx(
                classes.input,
                !!error && classes.errorInput,
                disabled && classes.disabled
              )}
              disabled={disabled}
              {...rest}
              onFocus={(e) => {
                rest.onFocus?.(e);
                setIsTooltipVisible(true);
              }}
              onBlur={(e) => {
                rest.onBlur?.(e);
                setIsTooltipVisible(false);
              }}
            />
          </Text>
        </Tooltip>
        {error && (
          <ErrorMessage className={classes.errorMessage} message={error} />
        )}
      </div>
    );
  }
);
