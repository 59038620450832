import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './DiscordCard.styles';

export default function DiscordCard(): JSX.Element {
  const { t: translation } = useTranslation();

  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  return (
    <div className={classes.discordCard}>
      <Headline className={classes.discordAd}>
        {translation('home.discord.title')}
      </Headline>
      <Text variant="b2" className={classes.discordText}>
        {translation('home.discord.text')}
      </Text>
      <a
        href="https://discord.gg/7fKEWhnh4x"
        className={classes.btnLink}
        target="_blank"
        rel="noreferrer"
      >
        <Button color="brand" buttonSize="xl">
          {translation('home.discord.btnText')}
        </Button>
      </a>
    </div>
  );
}
