import { createMakeStyles } from 'tss-react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useTheme = () => ({
  palette: {
    bronze: '#AE7C3C',
    silver: '#C7C8C8',
    gold: '#DEBB6C',
    greyBlue: '#1f2333',
    bg: {
      default: '#0A0D10',
      secondary: '#15191E',
      tertiary: '#0A0F14',
      system: '#222944',
      stripe: '#44474B',
      border: '#434D60',
      tone: 'rgba(0, 5, 13, 0.88)',
      toneLight: 'rgba(0, 5, 13, 0.3)',
      overlay: 'rgba(23, 23, 23, 0.8)',
      link: '#2D63FF',
      success: '#26C55B',
      warning: '#F2BB31',
      alert: '#CF152B',
      ghost: 'rgba(129, 136, 162, 0.1)',
      ghostLight: ' rgba(236, 246, 255, 0.1)',
    },
    typo: {
      primary: '#ECF6FF',
      secondary: 'rgba(236, 246, 255, 0.4)',
      tertiary: '#353940',
      link: '#2D63FF',
      linkHover: '#567FFA',
      linkPressed: '#0040F0',
      system: '#8188A2',
      success: '#26C55B',
      warning: '#F2BB31',
      alert: '#CF152B',
      brand: 'linear-gradient(89.03deg, #00DAA5 0.64%, #08C1D1 175.76%)',
      greenLight: '#03D7BD',
    },
    gradient: {
      gold: 'linear-gradient(168.12deg, #DEBB6C 4.13%, #E3CB8E 101.73%)',
      silver: 'linear-gradient(168.12deg, #C7C8C8 4.13%, #DDDEDE 101.73%)',
      bronze: 'linear-gradient(22.39deg, #AE7C3C 67.28%, #BB9768 408.39%)',
      promo: 'linear-gradient(89.03deg, #D34E31 0.64%, #FB18A0 175.76%)',
      brand: 'linear-gradient(89.03deg, #00DAA5 0.64%, #08C1D1 175.76%)',
      purple: 'linear-gradient(89.03deg, #702BC8 0.64%, #3452BE 175.76%)',
      blue: 'linear-gradient(143.74deg, #2D63FF 6.6%, #002BA7 93.95%)',
    },
    control: {
      default: {
        typo: '#ECF6FF',
        typoPlaceholder: 'rgba(236, 246, 255, 0.3)',
        typoLabel: '#8188A2',
        bg: '#121A26',
        bgActive: '#94C4FF',
        bgBorder: '#434D60',
        bgBorderHover: '#2D63FF',
        bgBorderError: '#CF152B',
      },
      primary: {
        typo: '#ECF6FF',
        bg: '#2D63FF',
        bgHover: '#144FFF',
        bgPressed: '#0040F0',
      },
      secondary: {
        typo: '#2D63FF',
        bg: 'rgba(19, 28, 52, 0.8)',
        bgHover: '#2D63FF',
        bgBorder: '#2D63FF',
        bgPressed: '#00021A',
      },
      brand: {
        typo: '#ECF6FF',
        bgBorder: 'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
        bgHover: 'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
        bgPressed: 'linear-gradient(88.89deg, #008D7C 0.73%, #01718A 102.93%)',
      },
      blueGradient: {
        bg: 'linear-gradient(143.74deg, rgba(45, 99, 255, 0.7) 6.6%, rgba(0, 43, 167, 0.7) 93.95%)',
        typo: '#ECF6FF',
        bgHover: 'linear-gradient(143.74deg, #2D63FF 6.6%, #002BA7 93.95%)',
        bgPressed:
          'linear-gradient(143.74deg, rgba(45, 99, 255, 0.6) 6.6%, rgba(0, 43, 167, 0.6) 93.95%)',
        typoPressed: 'rgba(236, 246, 255, 0.7)',
      },
    },
    chart: {
      blue: '#4273FF',
      yellow: '#FFCA42',
      purple: '#8528FD',
      green: '#4BD7A4',
      orange: '#FF7B42',
    },
  },

  layouts: {
    flex: {
      display: 'flex',
    },

    flexAICenter: {
      display: 'flex',
      alignItems: 'center',
    },

    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    marginXauto: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    marginLeftMinus16: {
      marginLeft: '-16px',
    },

    marginLeft20: {
      marginLeft: '20px',
    },

    marginLeftAuto: {
      marginLeft: 'auto',
    },

    marginTop24: {
      marginTop: '24px',
    },

    marginRight10: {
      marginRight: '10px',
    },

    flexJCcenter: {
      display: 'flex',
      justifyContent: 'center',
    },

    flexJCSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  fontStyles: {
    fontFamilyNunito: {
      fontFamily: "'Nunito'",
    },
    fontFamilyUbuntu: {
      fontFamily: "'Ubuntu', 'sans-serif'",
    },
    fontFamilyDm: {
      fontFamily: 'Dm Sans',
    },
    textSecondaryTitle: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    text18: {
      fontSize: '18px',
      lineHeight: '24px',
    },

    text12: {
      fontSize: '12px',
      lineHeight: '16px',
    },

    text14: {
      fontSize: '14px',
      lineHeight: '20px',
    },

    text10: {
      fontSize: '10px',
      lineHeight: '12px',
    },
    text16: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    text17: {
      fontSize: '17px',
      lineHeight: '22px',
    },
    text20: {
      fontSize: '20px',
      lineHeight: '28px',
    },
    text24: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    text30: {
      fontSize: '30px',
      lineHeight: '38px',
    },
  },
});

export const { makeStyles, useStyles } = createMakeStyles({ useTheme });
