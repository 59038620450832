import { BACKEND_URL, REDIRECT_URL } from './env';

interface ITransactionContactPayCount {
  count: number;
}

interface IWithdrawContactPayDetails {
  amount: number;
  pan: string;
  holder: string;
  year: number;
  month: number;
}

export function getTransactionContactPayCount(): Promise<ITransactionContactPayCount> {
  return fetch(`${BACKEND_URL}/api/contact-pay-service/transactions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((data) => data.json());
}

export function makeContactPayDeposit(
  amount: number,
  paymentMethod: string
): Promise<string> {
  return fetch(`${BACKEND_URL}/api/contact-pay-service/deposit`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount,
      successUrl: REDIRECT_URL,
      failedUrl: `${REDIRECT_URL}/failed_payment`,
      paymentMethod: paymentMethod,
    }),
  })
    .then((data) => data.json())
    .then((data) => data.redirectUrl);
}

export function withdrawContactPayFiat(
  details: IWithdrawContactPayDetails
): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/contact-pay-service/withdraw`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });
}
