import { FC } from 'react';

import clsx from 'clsx';
import useSWR from 'swr';

import { getUserGeo } from 'api';
import { MailIcon, OneD3LogoIcon, PinIcon, TextLogoIcon } from 'icons';
import { Typography } from 'ui-kit';

import { useStyle } from './Footer.styles';

interface IFooterProps {
  className?: string;
}

export const Footer: FC<IFooterProps> = ({ className }) => {
  const { Text } = Typography;
  const { classes } = useStyle();
  const { data: geo } = useSWR('api/geo', getUserGeo);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.block}>
        <TextLogoIcon />
        {
          /*geo?.country !== 'RU' && (*/
          <Text variant="b6" color="system">
            2023 GameGreed ME FZ LLC
          </Text>
          /*)*/
        }
      </div>
      <div className={classes.block}>
        {
          /*geo?.country !== 'RU' ? (*/
          <div className={classes.line}>
            <PinIcon />
            <Text variant="b6" color="system">
              UAE, Abu Dhabi, PO Box: 769568
            </Text>
          </div>
          /*) : (
          <>
            <Text variant="b6" color="system">
              ООО &quot;СИГУЛ&quot; ИНН: 7801651198 КПП: 150001001
            </Text>
            <Text variant="b6" color="system">
              Адрес: Республика Северная Осетия, г. ВЛАДИКАВКАЗ, улица
              ГРИБОЕДОВА, д. 4
            </Text>
            <Text variant="b6" color="system">
              тел: +79119813814
            </Text>
          </>
        )*/
        }
        <div className={classes.line}>
          <MailIcon />
          <a
            href="mailto:support@greed.ltd"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text variant="b6" color="system" className={classes.mail}>
              support@greed.ltd
            </Text>
          </a>
        </div>
      </div>
      <div className={classes.block}>
        <Text
          variant="b6"
          color="link"
          className={classes.link}
          onClick={() =>
            window.open(
              window.location.origin +
                /*(geo?.country !== 'RU'
                  ?*/ '/legal/world/TermsOfUse.pdf',
              /*: '/legal/ru/TermsOfUse.pdf')*/ '_blank'
            )
          }
        >
          Terms of use
        </Text>
        <Text
          variant="b6"
          color="link"
          className={classes.link}
          onClick={() =>
            window.open(
              window.location.origin +
                /*(geo?.country !== 'RU'
                  ?*/ '/legal/world/Privacy Policy.pdf',
              /* : '/legal/ru/Privacy.pdf')*/ '_blank'
            )
          }
        >
          Privacy
        </Text>
      </div>
      <div className={classes.block}>
        <Text
          variant="b6"
          color="link"
          className={classes.link}
          onClick={() =>
            window.open(
              window.location.origin +
                /*(geo?.country !== 'RU'
                  ?*/ '/legal/world/Cookies and how we use them.pdf' /*
                  : '/legal/ru/Cookies.pdf')*/,
              '_blank'
            )
          }
        >
          Cookies
        </Text>
        <Text
          variant="b6"
          color="link"
          className={classes.link}
          onClick={() =>
            window.open(
              window.location.origin +
                /*(geo?.country !== 'RU'
                  ? */ '/legal/world/Refund Policy.pdf',
              /*: '/legal/ru/Refund Policy.pdf')*/ '_blank'
            )
          }
        >
          Refund policy
        </Text>
      </div>
      {/*geo?.country === 'RU' && (
        <div className={classes.block}>
          <Text
            variant="b6"
            color="link"
            className={classes.link}
            onClick={() =>
              window.open(
                window.location.origin + '/legal/ru/Offer.pdf',
                '_blank'
              )
            }
          >
            Оферта
          </Text>
        </div>
      )*/}

      <div className={classes.line}>
        <img className={classes.img} src={'/images/footer/logo.png'} />
        <img
          className={classes.twofourWhiteImg}
          src={'/images/footer/twofour_white.png'}
        />
        {geo?.country !== 'RU' ? (
          <>
            <OneD3LogoIcon />
            <Text variant="b6" color="system" className={classes.d3text}>
              1D3 Digitech OÜ is an authorized global distributor of Game Greed
            </Text>
          </>
        ) : (
          <img className={classes.img} src={'/images/footer/cards.png'} />
        )}
      </div>
    </div>
  );
};
