import React, {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { removeZeroesAfterComma } from 'helpers';
import { useClickOutside } from 'hooks';
import { Button, InputNumber, Typography } from 'ui-kit';

import {
  getUserGeo,
  makeDeposit,
  makeContactPayDeposit,
} from '../../../../api';
import { Modal } from '../Modal';
import { useStyle } from './ManualDeposit.styles';
const { Headline, Text } = Typography;

interface IManualDepositProps {
  isOpen: boolean;
  onClose: () => void;
}

const Form: React.FC = () => {
  const [amountGc, setAmountGc] = useState<number>(0);
  const { classes } = useStyle();
  const [error, setError] = useState<string>('');

  const { data: transactions } = useSWR('api/transactions');
  const { t: translation } = useTranslation();
  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isContactPay = useMemo(() => geo?.country === 'RU', [geo?.country]);

  const PaymentButton = ({
    getImg,
    name,
    description,
    isActive,
    onClick,
  }: {
    getImg: () => ReactNode;
    name: string;
    description: string;
    isActive: boolean;
    onClick: () => void;
  }) => {
    return (
      <div
        className={`${classes.paymentButtom} ${
          isActive ? classes.activeBorder : ''
        }`}
        onClick={onClick}
      >
        <div
          className={`${classes.paymentImageContainer} ${
            isActive ? classes.activeBorder : ''
          }`}
        >
          {getImg()}
        </div>
        <div className={classes.paymentTexts}>
          <div>
            <Text block variant="b6">
              {name}
            </Text>
            <Text
              variant="b8"
              block
              color="typoPlaceholder"
              className={classes.paymentDescription}
            >
              {description}
            </Text>
          </div>
        </div>
      </div>
    );
  };
  const [paymentMethod, setPaymentMethod] = useState('card_acquiring_rub');

  const depos = useCallback(
    (amount: number) => {
      if (isContactPay) {
        return makeContactPayDeposit(amount, paymentMethod);
      }
      return makeDeposit(amount);
    },
    [geo?.country, paymentMethod]
  );
  const onDepositClick = async () => {
    const response = await depos(amountGc);
    if (response) {
      window.location.href = response;
    }
  };
  const currency = useMemo(
    () => (isContactPay ? 'RUB' : 'USD'),
    [isContactPay]
  );
  const currencySymbol = useMemo(
    () => (isContactPay ? '₽' : '$'),
    [isContactPay]
  );
  const currencyFactor = useMemo(
    () => (isContactPay ? 10 : 1000),
    [isContactPay]
  );
  const taxFactor = useMemo(
    () => (isContactPay ? 0 : 0.000025),
    [isContactPay]
  );
  const taxFactorGG = useMemo(
    () => (isContactPay ? 0 : 0.00003),
    [isContactPay]
  );

  const limit = useMemo(() => (isContactPay ? 1000 : 2000), [isContactPay]);
  const social = useMemo(
    () => (isContactPay ? 'Telegram' : 'Discord'),
    [isContactPay]
  );
  return (
    <div className={classes.form}>
      <Headline className={classes.title} variant="h3">
        {translation('profile.depositModal.title')}
      </Headline>
      <Text block variant="b5" className={classes.description}>
        {translation('profile.depositModal.description', {
          currency,
        })}{' '}
        <a>
          <Text variant="b5" color="link">
            {social}
          </Text>
        </a>
      </Text>
      {transactions?.count === 0 && (
        <div className={classes.promoBanner}>
          <Headline variant="h2" block>
            + 10%
          </Headline>
          <Text block variant="b4">
            {translation('profile.depositModal.promo')}
          </Text>
        </div>
      )}
      {isContactPay ? (
        <div
          style={{
            marginTop: '20px',
            marginBottom: '30px',
          }}
        >
          <Text
            variant="b6"
            block
            color="typoPlaceholder"
            className={classes.paymentDescription}
          >
            {translation('profile.depositModal.paymentType')}
          </Text>
          <div className={classes.paymentButtoms}>
            <PaymentButton
              getImg={() => {
                return (
                  <img
                    width="25px"
                    height="26px"
                    src="/images/profile/card.png"
                  ></img>
                );
              }}
              name={translation('profile.depositModal.paymentCard')}
              description={translation(
                'profile.depositModal.paymentCardDescription'
              )}
              isActive={paymentMethod === 'card_acquiring_rub'}
              onClick={() => setPaymentMethod('card_acquiring_rub')}
            />
            {/*
            <PaymentButton
              getImg={() => {
                return (
                  <img
                    width="29px"
                    height="16px"
                    src="/images/profile/sbp.png"
                  ></img>
                );
              }}
              name={translation('profile.depositModal.paymentSbp')}
              description={translation(
                'profile.depositModal.paymentSbpDescription'
              )}
              isActive={paymentMethod === 'sbp_rub'}
              onClick={() => setPaymentMethod('sbp_rub')}
            />
            */}
          </div>
        </div>
      ) : null}
      <div className={classes.inputContainer}>
        <InputNumber
          title="GC"
          placeholder={`${limit} GC`}
          suffix=" GC"
          className={classes.input}
          value={amountGc}
          decimalScale={2}
          onValueChange={({ value }) => {
            setAmountGc(+value);
          }}
          onBlur={() => {
            if (amountGc < limit) {
              setError(`Minimum ${limit} GC`);
            } else {
              setError('');
            }
          }}
          error={error}
        />
        <Text variant="b5" block>
          =
        </Text>
        <InputNumber
          title={currency}
          placeholder={` ${currency}`}
          suffix={` ${currency}`}
          decimalScale={2}
          className={classes.input}
          value={amountGc / currencyFactor}
          onValueChange={({ value }) => {
            setAmountGc(+value * currencyFactor);
          }}
          onBlur={() => {
            if (amountGc < limit) {
              setError(`Minimum ${limit} GC`);
            } else {
              setError('');
            }
          }}
        />
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.taxContainer}>
          {transactions?.count === 0 && (
            <div className={classes.taxDataContainer}>
              <Text variant="b6" block color="typoLabel">
                {translation('profile.depositModal.bonus')}
              </Text>
              <Text block variant="b5">
                {`+ ${removeZeroesAfterComma((amountGc * 0.1).toFixed(2))} GC`}
              </Text>
            </div>
          )}
          <div className={classes.taxDataContainer}>
            <Text variant="b6" block color="typoLabel">
              {translation('profile.depositModal.fee')}
            </Text>
            <Text block variant="b5">
              {`+ ${currencySymbol}${removeZeroesAfterComma(
                (amountGc * taxFactor).toFixed(2)
              )}`}
            </Text>
          </div>
          <div className={classes.taxDataContainer}>
            <Text variant="b6" block color="typoLabel">
              {translation('profile.depositModal.tax')}
            </Text>
            <Text block variant="b5">
              {`+ ${currencySymbol}${removeZeroesAfterComma(
                (amountGc * taxFactorGG).toFixed(2)
              )}`}
            </Text>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buyBtn}
            disabled={!!error || amountGc < limit}
            onClick={onDepositClick}
          >{`${translation('profile.depositModal.btn')} ${currencySymbol}${(
            (amountGc / currencyFactor) *
            (1 + (taxFactorGG + taxFactor) * 1000)
          ).toFixed(2)}`}</Button>
        </div>
      </div>
    </div>
  );
};

export const ManualDeposit: FC<IManualDepositProps> = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  useClickOutside(ref, onClose);

  const ModalContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <div ref={ref}>{children}</div>;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContainer>
        <Form />
      </ModalContainer>
    </Modal>
  );
};
